import { CSpinner, useColorModes } from '@coreui/react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useThemeStore } from '@src/store/theme';
import React, { Suspense, useEffect } from 'react';
import { HashRouter, Route, Routes } from 'react-router-dom';
import ProtectedRoute from './components/ProtectedRoute';
import './scss/style.scss';

const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'));

const Login = React.lazy(() => import('./views/login/Login'));
const Forgotpassword = React.lazy(
  () => import('./views/forgotpassword/Forgotpassword')
);

const App = () => {
  const { setColorMode } = useColorModes('nextchapter-dashboard-theme');
  const storedTheme = useThemeStore((state) => state.theme);

  useEffect(() => {
    setColorMode(storedTheme);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <HashRouter>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Suspense
          fallback={
            <div className="pt-3 text-center">
              <CSpinner color="primary" variant="grow" />
            </div>
          }
        >
          <Routes>
            <Route path="/auth/login" element={<Login />} />
            <Route path="/auth/forgotpassword" element={<Forgotpassword />} />
            {/* <Route path="*" element={<DefaultLayout />} /> */}
            <Route
              path="*"
              element={
                <ProtectedRoute>
                  <DefaultLayout />
                </ProtectedRoute>
              }
            />
          </Routes>
        </Suspense>
      </LocalizationProvider>
    </HashRouter>
  );
};

export default App;
