import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import auth from '../public/locales/en/auth.json';
import common from '../public/locales/en/common.json';
import customer from '../public/locales/en/customer.json';
import datagrid from '../public/locales/en/datagrid.json';
import pages from '../public/locales/en/pages.json';
import productsAndServices from '../public/locales/en/products-and-services.json';
import salesperson from '../public/locales/en/salesperson.json';
import settings from '../public/locales/en/settings.json';
import tasks from '../public/locales/en/tasks.json';
import vendor from '../public/locales/en/vendor.json';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    defaultNS: 'common',
    lng: 'en',
    fallbackLng: 'en',
    debug: true,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    resources: {
      en: {
        auth,
        common,
        customer,
        datagrid,
        pages,
        productsAndServices,
        salesperson,
        settings,
        vendor,
        tasks,
      },
    },
  });

export default i18n;
