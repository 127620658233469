import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

interface Store {
  id?: string;
  email?: string;
  token?: string;
  auth: boolean;
  set: (id: string, email: string, token: string) => void;
  clear: () => void;
}

const useStore = create<Store>()(
  persist(
    (set) => ({
      auth: false,
      set: (id: string, email: string, token: string) =>
        set(() => ({
          id: id,
          email: email,
          token: token,
          auth: true,
        })),
      clear: () =>
        set(() => ({
          id: undefined,
          email: undefined,
          token: undefined,
          auth: false,
        })),
    }),
    {
      name: 'auth-storage', // name of the item in the storage (must be unique)
      storage: createJSONStorage(() => sessionStorage), // (optional) by default, 'localStorage' is used
    }
  )
);

export default useStore;
